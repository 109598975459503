// noinspection NpmUsedModulesInstalled

import Vue from 'vue'

Vue.component('Chip', () => import('components/Chips/Chip.vue'))
Vue.component('ChipQueryTimeline', () => import('components/Chips/ChipQueryTimeline.vue'))
Vue.component('ChipStatusContratto', () => import('components/Chips/ChipStatusContratto.vue'))
Vue.component('ChipStatusUserSubscription', () => import('components/Chips/ChipStatusUserSubscription.vue'))
Vue.component('Stripe', () => import('components/Stripes/Stripe.vue'))
Vue.component('TooltipAnacInfo', () => import('components/Tooltips/TooltipAnacInfo.vue'))
Vue.component('TooltipAnnoAggiornamento', () => import('components/Tooltips/TooltipAnnoAggiornamento.vue'))
Vue.component('AutocompleteField', () => import('components/Forms/AutocompleteField.vue'))
Vue.component('BooleanselectField', () => import('components/Forms/BooleanselectField.vue'))
Vue.component('ButtonPrefer', () => import('components/Buttons/ButtonPrefer.vue'))
Vue.component('ButtonStepPreviousNext', () => import('components/Buttons/ButtonStepPreviousNext.vue'))
Vue.component('ButtonPrivacy', () => import('components/Buttons/ButtonPrivacy.vue'))
Vue.component('ButtonToSyncronize', () => import('components/Buttons/ButtonToSyncronize.vue'))
Vue.component('ListButtonDashboard', () => import('components/Buttons/ListButtonDashboard.vue'))
Vue.component('ListButtonDashboard2', () => import('components/Buttons/ListButtonDashboard2.vue'))
Vue.component('ListButtonFilterInstallations', () => import('components/Buttons/ListButtonFilterInstallations.vue'))
Vue.component('ListButtonFilterTenders', () => import('components/Buttons/ListButtonFilterTenders.vue'))
Vue.component('ButtonsDevMode', () => import('components/Buttons/ButtonsDevMode.vue'))
Vue.component('ButtonReadAllNotify', () => import('components/Buttons/ButtonReadAllNotify.vue'))
Vue.component('SelectTypeInstallations', () => import('components/Select/SelectTypeInstallations.vue'))
Vue.component('SelectAllSyncroInstallation', () => import('components/Select/SelectAllSyncroInstallation.vue'))
Vue.component('StepperSupplierForms', () => import('components/Steppers/StepperSupplierForms.vue'))
Vue.component('StepperSkeleton', () => import('components/Skeleton/StepperSkeleton.vue'))
Vue.component('SupplierFormsSkeleton', () => import('components/Skeleton/SupplierFormsSkeleton.vue'))
Vue.component('CategorieLPDialogTable', () => import('components/Dialogs/Tables/CategorieLPDialogTable.vue'))
Vue.component('CheckboxField', () => import('components/Forms/CheckboxField.vue'))
Vue.component('UserStatusTender', () => import('components/Status/UserStatusTender.vue'))
Vue.component('ChipTenderStatus', () => import('components/Status/ChipTenderStatus.vue'))
Vue.component('ChipTipoFornituraTender', () => import('components/Status/ChipTipoFornituraTender.vue'))
Vue.component('ChipSelectableSyncroPlatform', () => import('components/Status/ChipSelectableSyncroPlatform.vue'))
Vue.component('InfiniteScrollGeneral', () => import('components/Scroll/InfiniteScrollGeneral.vue'))
Vue.component('StatusAbilitation', () => import('components/Status/StatusAbilitation.vue'))
Vue.component('StatusSyncro', () => import('components/Status/StatusSyncro.vue'))
Vue.component('DateField', () => import('components/Forms/DateField.vue'))
Vue.component('DateCategorieLPField', () => import('components/Forms/DateCategorieLPField.vue'))
Vue.component('DocumentField', () => import('components/Forms/DocumentField.vue'))
Vue.component('DocumentArt38Field', () => import('components/Forms/DocumentArt38Field.vue'))
Vue.component('DocumentArt38Cell', () => import('components/Tables/Cells/DocumentArt38Cell.vue'))
Vue.component('DocDichiarazioniCategorieLPField', () => import('components/Forms/DocDichiarazioniCategorieLPField.vue'))
Vue.component('DocCertificazioneSOACategorieLPField', () => import('components/Forms/DocCertificazioneSOACategorieLPField.vue'))
Vue.component('HiddenField', () => import('components/Forms/HiddenField.vue'))
Vue.component('LabelField', () => import('components/Forms/LabelField.vue'))
Vue.component('RadioField', () => import('components/Forms/RadioField.vue'))
Vue.component('SelectField', () => import('components/Forms/SelectField.vue'))
Vue.component('NewSelectField', () => import('components/Forms/NewSelectField.vue'))
Vue.component('WsSelectField', () => import('components/Forms/WsSelectField.vue'))
Vue.component('SelectLivelloCategorieLPField', () => import('components/Forms/SelectLivelloCategorieLPField.vue'))
Vue.component('BannerGeneric', () => import('components/Extra/BannerGeneric.vue'))
Vue.component('ListFaqs', () => import('components/Lists/ListFaqs.vue'))
Vue.component('ListVideoGuide', () => import('components/Lists/ListVideoGuide.vue'))
Vue.component('ListManuali', () => import('components/Lists/ListManuali.vue'))
Vue.component('CardVideoGuida', () => import('components/Cards/CardVideoGuida.vue'))
Vue.component('CardTextGroupCatLP', () => import('components/Cards/CardTextGroupCatLP.vue'))
Vue.component('ChartPieSimple', () => import('components/Charts/ChartPieSimple.vue'))
Vue.component('ChartPieContrattoPartecipanti', () => import('components/Charts/ChartPieContrattoPartecipanti.vue'))
Vue.component('ChartAreaSAContrattiAggiudicati', () => import('components/Charts/ChartAreaSAContrattiAggiudicati.vue'))
Vue.component('ChartAreaOEContratti', () => import('components/Charts/ChartAreaOEContratti.vue'))
Vue.component('ProgressBarLinear', () => import('components/ProgressBar/ProgressBarLinear.vue'))
Vue.component('TreeContrattoPartecipanti', () => import('components/Tree/TreeContrattoPartecipanti.vue'))
Vue.component('TreeUserSubscription', () => import('components/Tree/TreeUserSubscription.vue'))
Vue.component('CardTenders', () => import('components/Cards/CardTenders.vue'))
Vue.component('CardMessages', () => import('components/Cards/CardMessages.vue'))
Vue.component('CardHomeInstallation', () => import('components/Cards/CardHomeInstallation.vue'))
Vue.component('CardHomeCanEnable', () => import('components/Cards/CardHomeCanEnable.vue'))
Vue.component('CardHomeInValidation', () => import('components/Cards/CardHomeInValidation.vue'))
Vue.component('CardHomeValidated', () => import('components/Cards/CardHomeValidated.vue'))
Vue.component('CardHomeExpiring', () => import('components/Cards/CardHomeExpiring.vue'))
Vue.component('CardHomeMessagesNotRead', () => import('components/Cards/CardHomeMessagesNotRead.vue'))
Vue.component('CardInstallations', () => import('components/Cards/CardInstallations.vue'))
Vue.component('CardInstallationSyncro', () => import('components/Cards/CardInstallationSyncro.vue'))
Vue.component('RowTableTenderResultCard', () => import('components/Rows/RowTableTenderResultCard.vue'))
Vue.component('RowTableTender', () => import('components/Rows/RowTableTender.vue'))
Vue.component('RowFilterStazioneAppaltante', () => import('components/Rows/RowFilterStazioneAppaltante.vue'))
Vue.component('ItemNotify', () => import('components/Cards/ItemNotify.vue'))
Vue.component('TableField', () => import('components/Forms/TableField.vue'))
Vue.component('TableContattiPrincipaliField', () => import('components/Forms/TableContattiPrincipaliField.vue'))
Vue.component('TableCategorieLPField', () => import('components/Forms/TableCategorieLPField.vue'))
Vue.component('TextareaField', () => import('components/Forms/TextareaField.vue'))
Vue.component('TextField', () => import('components/Forms/TextField.vue'))
Vue.component('EmailField', () => import('components/Forms/EmailField.vue'))
Vue.component('NumberField', () => import('components/Forms/NumberField.vue'))
Vue.component('PhoneNumberField', () => import('components/Forms/PhoneNumberField.vue'))
Vue.component('YearField', () => import('components/Forms/YearField.vue'))
Vue.component('TreeField', () => import('components/Forms/TreeField.vue'))
Vue.component('ImportField', () => import('components/Forms/ImportField.vue'))
Vue.component('ImportCategorieLPField', () => import('components/Forms/ImportCategorieLPField.vue'))
Vue.component('SupplierDocumentField', () => import('components/Forms/SupplierDocumentField.vue'))
Vue.component('CheckboxCell', () => import('components/Tables/Cells/CheckboxCell.vue'))
Vue.component('DateCell', () => import('components/Tables/Cells/DateCell.vue'))
Vue.component('UrlCell', () => import('components/Tables/Cells/UrlCell.vue'))
Vue.component('StatusSyncroCell', () => import('components/Tables/Cells/StatusSyncroCell.vue'))
Vue.component('CheckboxListSpecializzazioniCell', () => import('components/Tables/Cells/CheckboxListSpecializzazioniCell.vue'))
Vue.component('StatusCell', () => import('components/Tables/Cells/StatusCell.vue'))
Vue.component('DocumentCell', () => import('components/Tables/Cells/DocumentCell.vue'))
Vue.component('UrlTextCell', () => import('components/Tables/Cells/UrlTextCell.vue'))
Vue.component('ActionButtonCell', () => import('components/Tables/Cells/ActionButtonCell.vue'))
Vue.component('PreferCell', () => import('components/Tables/Cells/PreferCell.vue'))
Vue.component('SelectCell', () => import('components/Tables/Cells/SelectCell.vue'))
Vue.component('NewSelectCell', () => import('components/Tables/Cells/NewSelectCell.vue'))
Vue.component('SelectCheckboxCell', () => import('components/Tables/Cells/SelectCheckboxCell.vue'))
Vue.component('ImportCell', () => import('components/Tables/Cells/ImportCell.vue'))
Vue.component('NumberCell', () => import('components/Tables/Cells/NumberCell.vue'))
Vue.component('PhoneNumberCell', () => import('components/Tables/Cells/PhoneNumberCell.vue'))
Vue.component('TextCell', () => import('components/Tables/Cells/TextCell.vue'))
Vue.component('OptionGroupCell', () => import('components/Tables/Cells/OptionGroupCell.vue'))
Vue.component('PrivacyCell', () => import('components/Tables/Cells/PrivacyCell.vue'))
Vue.component('GeneralDialogTable', () => import('components/Dialogs/Tables/GeneralDialogTable.vue'))
Vue.component('CertificazioniDialogTable', () => import('components/Dialogs/Tables/CertificazioniDialogTable.vue'))
Vue.component('ComponentiDialogTable', () => import('components/Dialogs/Tables/ComponentiDialogTable.vue'))
Vue.component('GestoriAmbientaliDialogTable', () => import('components/Dialogs/Tables/GestoriAmbientaliDialogTable.vue'))
Vue.component('PrestazioniProfessionaliDialogTable', () => import('components/Dialogs/Tables/PrestazioniProfessionaliDialogTable.vue'))
Vue.component('OpereDialogTable', () => import('components/Dialogs/Tables/OpereDialogTable.vue'))
Vue.component('TreeDialogTable', () => import('components/Dialogs/Tables/TreeDialogTable.vue'))
Vue.component('OperatoriList', () => import('components/Tables/Lists/OperatoriList.vue'))
Vue.component('SelectGeneral', () => import('components/Select/SelectGeneral.vue'))
Vue.component('ServiziList', () => import('components/Tables/Lists/ServiziList.vue'))
Vue.component('PrestazioniList', () => import('components/Tables/Lists/PrestazioniList.vue'))
Vue.component('DialogQueryTimeline', () => import('components/Dialogs/DialogQueryTimeline.vue'))
Vue.component('DialogQueryFinished', () => import('components/Dialogs/DialogQueryFinished.vue'))
Vue.component('DialogQueryDecrease', () => import('components/Dialogs/DialogQueryDecrease.vue'))
Vue.component('DialogErrorBenchmark', () => import('components/Dialogs/DialogErrorBenchmark.vue'))
Vue.component('FilterDialogListAsync', () => import('components/Dialogs/FilterDialogListAsync.vue'))
Vue.component('DialogNotify', () => import('components/Dialogs/DialogNotify.vue'))
Vue.component('DialogSyncro', () => import('components/Dialogs/DialogSyncro.vue'))
Vue.component('DialogSyncroSubscription', () => import('components/Dialogs/DialogSyncroSubscription.vue'))
Vue.component('DialogConfirmSyncroSubscription', () => import('components/Dialogs/DialogConfirmSyncroSubscription.vue'))
Vue.component('DialogDeSyncro', () => import('components/Dialogs/DialogDeSyncro.vue'))
Vue.component('UpgradePlus', () => import('components/Header/UpgradePlus.vue'))
Vue.component('DialogForUserPlus', () => import('components/Dialogs/DialogForUserPlus.vue'))
Vue.component('DialogPasswordModified', () => import('components/Dialogs/DialogPasswordModified.vue'))
Vue.component('DialogVerifyEmail', () => import('components/Dialogs/DialogVerifyEmail.vue'))
Vue.component('DialogCredentialSyncroErrorEmailUsed', () => import('components/Dialogs/DialogCredentialSyncroErrorEmailUsed.vue'))
Vue.component('DialogDeleteRow', () => import('components/Dialogs/DialogDeleteRow.vue'))
Vue.component('DialogCredentialSyncroEmailSent', () => import('components/Dialogs/DialogCredentialSyncroEmailSent.vue'))
Vue.component('DialogCredentialSyncroComplete', () => import('components/Dialogs/DialogCredentialSyncroComplete.vue'))
Vue.component('DialogPaperBoyNews', () => import('components/Dialogs/DialogPaperBoyNews.vue'))
Vue.component('DialogDownloadMobileApp', () => import('components/Dialogs/DialogDownloadMobileApp.vue'))
Vue.component('DialogPromotionalInfo', () => import('components/Dialogs/DialogPromotionalInfo.vue'))
Vue.component('Tipologie_servizi_assistenzaList', () => import('components/Tables/Lists/Tipologie_servizi_assistenzaList.vue'))
Vue.component('CheckboxListSpecializzazioniField', () => import('components/Forms/CheckboxListSpecializzazioniField.vue'))
Vue.component('OptionGroupField', () => import('components/Forms/OptionGroupField.vue'))
Vue.component('PercentualeComponentiField', () => import('components/Forms/PercentualeComponentiField.vue'))
Vue.component('LoadingScreen', () => import('components/LoadingScreen.vue'))
Vue.component('LoginLogout', () => import('components/Auth/LoginLogout.vue'))
Vue.component('AvatarLogin', () => import('components/Auth/AvatarLogin.vue'))
Vue.component('LoginRegister', () => import('components/Auth/LoginRegister.vue'))
Vue.component('ListMenu', () => import('components/MenuLeft/ListMenu.vue'))
Vue.component('LogoGlobe', () => import('components/Images/LogoGlobe.vue'))
Vue.component('SelectLang', () => import('components/Select/SelectLang.vue'))
Vue.component('ListMenuTop', () => import('components/Header/ListMenuTop.vue'))
Vue.component('Logo', () => import('components/Header/Logo.vue'))
Vue.component('LogoText', () => import('components/Images/LogoText.vue'))
Vue.component('JwtLogin', () => import('components/Images/JwtLogin.vue'))
Vue.component('IconReadOnly', () => import('components/Images/IconReadOnly.vue'))
Vue.component('BackgroundAuth', () => import('components/Images/BackgroundAuth.vue'))
Vue.component('ButtonReloadTableMessages', () => import('components/Buttons/ButtonReloadTableMessages.vue'))
Vue.component('ButtonLogout', () => import('components/Buttons/ButtonLogout.vue'))
Vue.component('ButtonSubscription', () => import('components/Buttons/ButtonSubscription.vue'))
Vue.component('ButtonAccount', () => import('components/Buttons/ButtonAccount.vue'))
Vue.component('ButtonUrl', () => import('components/Buttons/ButtonUrl.vue'))
Vue.component('ButtonGoToPageGlass', () => import('components/Buttons/ButtonGoToPageGlass.vue'))
Vue.component('ButtonNotify', () => import('components/Buttons/ButtonNotify.vue'))
Vue.component('ItemButtonManageAccount', () => import('components/Buttons/ItemButtonManageAccount.vue'))
// Vue.component('ItemButtonManageSubscription', () => import('components/Buttons/ItemButtonManageSubscription.vue'))
Vue.component('ButtonSupport', () => import('components/Buttons/ButtonSupport.vue'))
Vue.component('MiniCard', () => import('components/Cards/MiniCard.vue'))
Vue.component('MiniCardADV', () => import('components/Cards/MiniCardADV.vue'))
Vue.component('ButtonSyncro', () => import('components/Buttons/ButtonSyncro.vue'))
Vue.component('ButtonSyncroAccount', () => import('components/Buttons/ButtonSyncroAccount.vue'))
Vue.component('ButtonSyncroSubscription', () => import('components/Buttons/ButtonSyncroSubscription.vue'))
Vue.component('SelectPrefer', () => import('components/Select/SelectPrefer.vue'))
Vue.component('TopNotify', () => import('components/Header/TopNotify.vue'))
Vue.component('ListNotifications', () => import('components/Lists/ListNotifications.vue'))
Vue.component('ListTerms', () => import('components/Lists/ListTerms.vue'))
Vue.component('MenuLeft', () => import('components/MenuLeft/MenuLeft.vue'))
Vue.component('Footer', () => import('components/Footer/Footer.vue'))
Vue.component('FooterPageBenchmarks', () => import('components/Footer/FooterPageBenchmarks.vue'))
Vue.component('Header', () => import('components/Header/Header.vue'))
Vue.component('HeaderTable', () => import('components/Tables/Headers/HeaderTable.vue'))
Vue.component('HeaderTableBenchmarks', () => import('components/Tables/Headers/HeaderTableBenchmarks.vue'))
Vue.component('HeaderTableBenchmarkOE', () => import('components/Tables/Headers/HeaderTableBenchmarkOE.vue'))
Vue.component('HeaderSort', () => import('components/Tables/Headers/HeaderSort.vue'))
Vue.component('BottomTable', () => import('components/Tables/Components/BottomTable.vue'))
Vue.component('TopTable', () => import('components/Tables/Components/TopTable.vue'))
Vue.component('TopSimpleCard', () => import('components/Tables/Components/TopSimpleCard.vue'))
Vue.component('NoDataTable', () => import('components/Tables/Components/NoDataTable.vue'))
Vue.component('PopupCellTable', () => import('components/Tables/Components/PopupCellTable.vue'))
Vue.component('PaginationTable', () => import('components/Tables/Components/PaginationTable.vue'))
Vue.component('ButtonsPaginationTable', () => import('components/Tables/Components/ButtonsPaginationTable.vue'))
Vue.component('PaginationTableOld', () => import('components/Tables/Components/PaginationTableOld.vue'))
Vue.component('ButtonsPaginationTableOld', () => import('components/Tables/Components/ButtonsPaginationTableOld.vue'))
Vue.component('BodyTable', () => import('components/Tables/Components/BodyTable.vue'))
Vue.component('BodyTableMessages', () => import('components/Tables/Components/BodyTableMessages.vue'))
Vue.component('AllDialogs', () => import('components/Dialogs/AllDialogs.vue'))
Vue.component('TimelineFilterQuery', () => import('components/Timelines/TimelineFilterQuery.vue'))
/**************************/
/* PAGES                  */
/**************************/
Vue.component('PageHeader', () => import('pages/PageHeader.vue'))
Vue.component('PageBenchmarkDetailContratto', () => import('pages/PageBenchmarkDetailContratto.vue'))
Vue.component('PageBenchmarkDetailStazioneAppaltante', () => import('pages/PageBenchmarkDetailStazioneAppaltante.vue'))
Vue.component('PageBenchmarkDetailOperatoreEconomico', () => import('pages/PageBenchmarkDetailOperatoreEconomico.vue'))
/**************************/
/* TABLES                 */
/**************************/
Vue.component('TableGeneral', () => import('components/Tables/TableGeneral.vue'))
Vue.component('TableSimpleCard', () => import('components/Tables/TableSimpleCard.vue'))
// Vue.component('TableInstallationsAvailable', () => import('components/Tables/TableInstallationsAvailable.vue'))
Vue.component('TableInstallations', () => import('components/Tables/TableInstallations.vue'))
Vue.component('TableInstallationSyncro', () => import('components/Tables/TableInstallationSyncro.vue'))
Vue.component('TableInfoBandi', () => import('components/Tables/TableInfoBandi.vue'))
Vue.component('TableTenders', () => import('components/Tables/TableTenders.vue'))
Vue.component('TableBenchmarks', () => import('components/Tables/TableBenchmarks.vue'))
Vue.component('TableBenchmarkOE', () => import('components/Tables/TableBenchmarkOE.vue'))
Vue.component('TableBenchmarkSA', () => import('components/Tables/TableBenchmarkSA.vue'))
Vue.component('TableInfoBandi', () => import('components/Tables/TableInfoBandi.vue'))
Vue.component('TableTenderResult', () => import('components/Tables/TableTenderResult.vue'))
Vue.component('TableMessages', () => import('components/Tables/TableMessages.vue'))
/* Cards */
Vue.component('CardTableInfoBandi', () => import('components/Tables/Cards/CardTableInfoBandi.vue'))
Vue.component('CardTableTenderResult', () => import('components/Tables/Cards/CardTableTenderResult.vue'))
Vue.component('CardTableBenchmark', () => import('components/Tables/Cards/CardTableBenchmark.vue'))
Vue.component('CardTableMediumBenchmark', () => import('components/Tables/Cards/CardTableMediumBenchmark.vue'))
Vue.component('CardTableSmallBenchmark', () => import('components/Tables/Cards/CardTableSmallBenchmark.vue'))
Vue.component('CardTableBenchmarkOE', () => import('components/Tables/Cards/CardTableBenchmarkOE.vue'))
Vue.component('CardTableApplyLens', () => import('components/Tables/Cards/CardTableApplyLens.vue'))
/* Rows */
Vue.component('RowTableBenchmark', () => import('components/Tables/Rows/RowTableBenchmark.vue'))
Vue.component('RowTableBenchmarkOE', () => import('components/Tables/Rows/RowTableBenchmarkOE.vue'))
/* NewComponents */
Vue.component('Default', () => import('components/Tables/NewComponents/Default.vue'))
Vue.component('Importo', () => import('components/Tables/NewComponents/Importo.vue'))
Vue.component('Oggetto', () => import('components/Tables/NewComponents/Oggetto.vue'))
Vue.component('StatoAggiudicazione', () => import('components/Tables/NewComponents/StatoAggiudicazione.vue'))
Vue.component('SAOE', () => import('components/Tables/NewComponents/SAOE.vue'))
Vue.component('Status', () => import('components/Tables/NewComponents/Status.vue'))
Vue.component('Timeline', () => import('components/Tables/NewComponents/Timeline.vue'))

/**************************/
/* FILTERS                */
/**************************/
/* Blocks */
Vue.component('FiltersExpansion', () => import('components/Filters/Blocks/FiltersExpansion.vue'))
Vue.component('FiltersBlock', () => import('components/Filters/Blocks/FiltersBlock.vue'))
/* Input */
Vue.component('FilterDate', () => import('components/Filters/Inputs/FilterDate.vue'))
Vue.component('FilterText', () => import('components/Filters/Inputs/FilterText.vue'))
Vue.component('FilterTypeTender', () => import('components/Filters/Inputs/FilterTypeTender.vue'))
Vue.component('FilterStatusToSyncro', () => import('components/Filters/Inputs/FilterStatusToSyncro.vue'))
Vue.component('FilterStatusMessages', () => import('components/Filters/Inputs/FilterStatusMessages.vue'))
Vue.component('FilterRange', () => import('components/Filters/Inputs/FilterRange.vue'))
Vue.component('FilterInputTreeDialog', () => import('components/Filters/Inputs/FilterInputTreeDialog.vue'))
Vue.component('FilterInputListAsync', () => import('components/Filters/Inputs/FilterInputListAsync.vue'))
Vue.component('FilterReset', () => import('components/Filters/Inputs/FilterReset.vue'))
Vue.component('FilterApply', () => import('components/Filters/Inputs/FilterApply.vue'))
Vue.component('FilterButtons', () => import('components/Filters/Inputs/FilterButtons.vue'))
Vue.component('FilterCheckbox', () => import('components/Filters/Inputs/FilterCheckbox.vue'))
Vue.component('FilterSelect', () => import('components/Filters/Inputs/FilterSelect.vue'))
Vue.component('FilterStatusInstallations', () => import('components/Filters/Inputs/FilterStatusInstallations.vue'))
/**************************/
/* CARDS                  */
/**************************/
Vue.component('CardHeader', () => import('components/Cards/CardHeader.vue'))
Vue.component('CardTenderPublic', () => import('components/Cards/CardTenderPublic.vue'))
Vue.component('CardTenderSubscription', () => import('components/Cards/CardTenderSubscription.vue'))
Vue.component('CardTenderInvited', () => import('components/Cards/CardTenderInvited.vue'))
Vue.component('CardInfoBandi', () => import('components/Cards/CardInfoBandi.vue'))
Vue.component('CardTenderResult', () => import('components/Cards/CardTenderResult.vue'))
Vue.component('CardCompletamentoProfilo', () => import('components/Cards/CardCompletamentoProfilo.vue'))
Vue.component('CardAnalisiBenchmark', () => import('components/Cards/CardAnalisiBenchmark.vue'))
Vue.component('CardNews', () => import('components/Cards/CardNews.vue'))
Vue.component('CardNeedSupport', () => import('components/Cards/CardNeedSupport.vue'))
/* Benchmarks */
Vue.component('CardContrattoDurata', () => import('components/Cards/Benchmarks/CardContrattoDurata.vue'))
Vue.component('CardContrattoStazioneAppaltante', () => import('components/Cards/Benchmarks/CardContrattoStazioneAppaltante.vue'))
Vue.component('CardOEContratti', () => import('components/Cards/Benchmarks/CardOEContratti.vue'))
Vue.component('CardOETotalAwards', () => import('components/Cards/Benchmarks/CardOETotalAwards.vue'))
Vue.component('CardOEAmountAwarded', () => import('components/Cards/Benchmarks/CardOEAmountAwarded.vue'))
Vue.component('CardSAContrattiAggiudicati', () => import('components/Cards/Benchmarks/CardSAContrattiAggiudicati.vue'))
Vue.component('CardSAContrattiInCorso', () => import('components/Cards/Benchmarks/CardSAContrattiInCorso.vue'))
Vue.component('CardSAAmountAwarded', () => import('components/Cards/Benchmarks/CardSAAmountAwarded.vue'))
Vue.component('CardContrattoImporti', () => import('components/Cards/Benchmarks/CardContrattoImporti.vue'))
Vue.component('CardContrattoPartecipanti', () => import('components/Cards/Benchmarks/CardContrattoPartecipanti.vue'))
Vue.component('CardBlurred', () => import('components/Cards/CardBlurred.vue'))
